import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchCoinsFromAPI } from '../../../api/slices/Coin.api'

export interface ICoin {
  active: number
  id: number
  max_multiplier: number
  name: string
  slug: string
  symbol: string
}

const initialState: ICoin[] = []

export const coinSlice = createSlice({
  name: 'coins',
  initialState,
  reducers: {
    setCoins: (state, action: PayloadAction<ICoin[]>) => {
      return action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCoinsFromAPI.fulfilled, (state, action) => {
      return action.payload
    })
  }
})

// Action creators are generated for each case reducer function
export const { setCoins } = coinSlice.actions

export default coinSlice.reducer

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { DOMAIN } from '../App';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { setUser } from '../store/features/userSlice/userSlice';

export default function MarketTrade() {
  const [tradeInformation, setTradeInformation] = useState({
    buy_amount: 0,
    sell_amount: 0
  })

  const activeCoinId = useSelector((state: RootState) => state.innerState.activeCoinId)

  const dispatch = useDispatch<AppDispatch>()

  const updateUserDataAfterTrade = () => {
    console.log('Updating user balance')

    axios
    .get(`${DOMAIN}/api/self`, {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    })
    .then(async function (response) {
      console.log('client', response)
      // TODO: set user data in redux
      dispatch(setUser(response.data))
    })
    .catch(function (error) {
      console.log(error)
    })
  }

  const handleFormInputChange = (event: any) => {
    setTradeInformation({
      ...tradeInformation,
      [event.target.name]: event.target.value
    })
  }

  const handleSpotBuy = (event: any) => {
    setTransferMessage('...')
    event.preventDefault();
    console.log('Spot Buy');

    // dialog
    handleOpen()
    setTradeInformation({
      ...tradeInformation,
      buy_amount: 0
    })

    axios
      .post(`${DOMAIN}/api/spot/trade/by/coin`, {
        coin_id: activeCoinId,
        side: "buy",
        quantity: tradeInformation.buy_amount
      }, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      })
      .then(async function (response) {
        console.log(response)

        setTransferMessage('Trade was successful!')
        updateUserDataAfterTrade()
      })
      .catch(function (error) {
        console.log(error)

        setTransferMessage(error.response.data.message)
      })
  }

  const handleSpotSell = (event: any) => {
    setTransferMessage('...')
    event.preventDefault();
    console.log('Spot Sell');

    handleOpen()
    setTradeInformation({
      ...tradeInformation,
      sell_amount: 0
    })

    axios
      .post(`${DOMAIN}/api/spot/trade/by/coin`, {
        coin_id: activeCoinId,
        side: "sell",
        quantity: tradeInformation.sell_amount
      }, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      })
      .then(async function (response) {
        console.log(response)

        setTransferMessage('Trade was successful!')
        updateUserDataAfterTrade()
      })
      .catch(function (error) {
        console.log(error)

        setTransferMessage(error.response.data.message)
      })
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(!open)

  const [transferMessage, setTransferMessage] = useState('')

  return (
    <>
      <div className="market-trade">
        <Tabs defaultActiveKey="spot-coin">
          <Tab eventKey="spot-coin" title="Spot by Coin">
            <div className="d-flex justify-content-between">
              <div className="market-trade-buy">
                <form action="#">
                  <div className="input-group">
                    <input
                      name="buy_amount"
                      value={tradeInformation.buy_amount}
                      onChange={handleFormInputChange}
                      type="number"
                      className="form-control"
                      placeholder="Price"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">USDT</span>
                    </div>
                  </div>
                  {/* <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div> */}
                  <ul className="market-trade-list">
                    <li>
                      <a href="#!">25%</a>
                    </li>
                    <li>
                      <a href="#!">50%</a>
                    </li>
                    <li>
                      <a href="#!">75%</a>
                    </li>
                    <li>
                      <a href="#!">100%</a>
                    </li>
                  </ul>
                  <p>
                    Available: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Volume: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Margin: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Fee: <span>0 BTC = 0 USD</span>
                  </p>
                  <button className="btn buy" onClick={handleSpotBuy}>
                    Buy
                  </button>
                </form>
              </div>
              <div className="market-trade-sell">
                <form action="#">
                  <div className="input-group">
                    <input
                      name="sell_amount"
                      value={tradeInformation.sell_amount}
                      onChange={handleFormInputChange}
                      type="number"
                      className="form-control"
                      placeholder="Price"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">USDT</span>
                    </div>
                  </div>
                  {/* <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div> */}
                  <ul className="market-trade-list">
                    <li>
                      <a href="#!">25%</a>
                    </li>
                    <li>
                      <a href="#!">50%</a>
                    </li>
                    <li>
                      <a href="#!">75%</a>
                    </li>
                    <li>
                      <a href="#!">100%</a>
                    </li>
                  </ul>
                  <p>
                    Available: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Volume: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Margin: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Fee: <span>0 BTC = 0 USD</span>
                  </p>
                  <button className="btn sell" onClick={handleSpotSell}>Sell</button>
                </form>
              </div>
            </div>
          </Tab>
          {/* <Tab eventKey="spot-coin" title="Spot by Coin">
            <div className="d-flex justify-content-between">
              <div className="market-trade-buy">
                <form action="#">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Quantity"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li>
                      <a href="#!">25%</a>
                    </li>
                    <li>
                      <a href="#!">50%</a>
                    </li>
                    <li>
                      <a href="#!">75%</a>
                    </li>
                    <li>
                      <a href="#!">100%</a>
                    </li>
                  </ul>
                  <p>
                    Available: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Volume: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Margin: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Fee: <span>0 BTC = 0 USD</span>
                  </p>
                  <button className="btn buy" onClick={handleSpotBuy}>
                    Buy
                  </button>
                </form>
              </div>
              <div className="market-trade-sell">
                <form action="#">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Quantity"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li>
                      <a href="#!">25%</a>
                    </li>
                    <li>
                      <a href="#!">50%</a>
                    </li>
                    <li>
                      <a href="#!">75%</a>
                    </li>
                    <li>
                      <a href="#!">100%</a>
                    </li>
                  </ul>
                  <p>
                    Available: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Volume: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Margin: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Fee: <span>0 BTC = 0 USD</span>
                  </p>
                  <button className="btn sell" onClick={handleSpotSell}>Sell</button>
                </form>
              </div>
            </div>
          </Tab>
          <Tab eventKey="spot-percent" title="Spot by Percentage">
            <div className="d-flex justify-content-between">
              <div className="market-trade-buy">
                <form action="#">
                  <div className="input-group">
                    <input
                      min="0"
                      max="100"
                      type="number"
                      className="form-control"
                      placeholder="Percentage"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li>
                      <a href="#!">25%</a>
                    </li>
                    <li>
                      <a href="#!">50%</a>
                    </li>
                    <li>
                      <a href="#!">75%</a>
                    </li>
                    <li>
                      <a href="#!">100%</a>
                    </li>
                  </ul>
                  <p>
                    Available: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Volume: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Margin: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Fee: <span>0 BTC = 0 USD</span>
                  </p>
                  <button className="btn buy" onClick={handleSpotBuy}>
                    Buy
                  </button>
                </form>
              </div>
              <div className="market-trade-sell">
                <form action="#">
                  <div className="input-group">
                    <input
                      min="0"
                      max="100"
                      type="number"
                      className="form-control"
                      placeholder="Percentage"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li>
                      <a href="#!">25%</a>
                    </li>
                    <li>
                      <a href="#!">50%</a>
                    </li>
                    <li>
                      <a href="#!">75%</a>
                    </li>
                    <li>
                      <a href="#!">100%</a>
                    </li>
                  </ul>
                  <p>
                    Available: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Volume: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Margin: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Fee: <span>0 BTC = 0 USD</span>
                  </p>
                  <button className="btn sell" onClick={handleSpotSell}>Sell</button>
                </form>
              </div>
            </div>
          </Tab> */}
        </Tabs>
      </div>
      {open && (
        <div className="z-50 popup fixed inset-0 w-full bg-[#000]/15 flex items-center justify-center">
          <div className="bg-[#fff] rounded-[8px] p-4">
            <p className='text-[28px] font-medium'>Message</p>
            <p className='text-base '>{transferMessage}</p>
            <button className='mt-8 btn w-full flex items-center justify-center bg-[#0567EA] text-[#fff] hover:bg-[#0567EA]/90' onClick={handleOpen}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

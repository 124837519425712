import React, { Component, useEffect } from 'react';
import HistoryOrder from '../components/HistoryOrder';
import MarketHistory from '../components/MarketHistory';
import MarketNews from '../components/MarketNews';
import MarketPairs from '../components/MarketPairs';
import MarketTrade from '../components/MarketTrade';
import OrderBook from '../components/OrderBook';
import TradingChart from '../components/TradingChart';
import TradingChartDark from '../components/TradingChartDark';
import { ThemeConsumer } from '../context/ThemeContext';
import Cookies from 'js-cookie';
import axios from 'axios';
import { DOMAIN } from '../App';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { setUser } from '../store/features/userSlice/userSlice';
import { useNavigate } from 'react-router-dom';

export const Exchange = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleUserAuthentication = () => {
    console.log('handleUserAuthentication')

    const token = Cookies.get('token')

    console.log('token', token)
    if (token) {
      console.log('token & userID present')
      axios
        .get(`${DOMAIN}/api/self`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(async function (response) {
          console.log('client', response)

          // TODO: set user data in redux
          dispatch(setUser(response.data))
        })
        .catch(function (error) {
          Cookies.remove('token')
          Cookies.remove('userID')
          navigate('/')
          console.log(error)
        })
    }
  }

  useEffect(() => {
    handleUserAuthentication()
  }, [])

  return (
    <>
      <div className="container-fluid mtb15 no-fluid">
        <div className="row sm-gutters">
          <div className="col-sm-12 col-md-3">
            <MarketPairs />
          </div>
          <div className="col-sm-12 col-md-6">
            <ThemeConsumer>
              {({ data }) => {
                return data.theme === 'light' ? (
                  <TradingChart />
                ) : (
                  <TradingChartDark />
                );
              }}
            </ThemeConsumer>
            <MarketTrade />
          </div>
          <div className="col-md-3">
            <OrderBook />
            <MarketHistory />
          </div>
          <div className="col-md-3">
            <MarketNews />
          </div>
          <div className="col-md-9">
            <HistoryOrder />
          </div>
        </div>
      </div>
    </>
  )
}
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import axios from 'axios';
import { DOMAIN } from '../App';
import Cookies from 'js-cookie';
import { setUser } from '../store/features/userSlice/userSlice'
import { AppDispatch } from '../store';

export default function Login() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const [userInformation, setUserInformation] = useState({
    email: '',
    password: ''
  })

  const handleUserAuthentication = () => {
    console.log('handleUserAuthentication')

    const token = Cookies.get('token')

    console.log('token', token)

    if (token) {
      console.log('token & userID present')
      axios
        .get(`${DOMAIN}/api/self`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(async function (response) {
          console.log('client', response)

          // TODO: set user data in redux
          dispatch(setUser(response.data))

          // navigate to dashboard
          // window.location.pathname = '/dashboard'
          navigate('/dashboard')
        })
        .catch(function (error) {
          Cookies.remove('token')
          Cookies.remove('userID')
          // window.location.pathname = '/'
          navigate('/')
          // setLoading(false)
          console.log(error)
        })
    }
  }

  useEffect(() => {
    console.log('location', location)
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      console.log('Token from URL:', token);
      // You can use the token as needed, e.g., set it in cookies or state
      Cookies.set('token', token);
      handleUserAuthentication()
    }
  }, [location])

  const handleFormInputChange = (event: any) => {
    setUserInformation({
      ...userInformation,
      [event.target.name]: event.target.value
    })
  }

  const handleLoginClick = (event: any) => {
    console.log('login pushed', userInformation)
    axios
      .post(`${DOMAIN}/api/login`, {
        email: userInformation.email,
        password: userInformation.password
      })
      .then(async function (response) {
        console.log(response)
        // set access token
        Cookies.set('token', response.data.meta.token)
        Cookies.set('userID', response.data.meta.token)

        console.log('user', response.data.data)

        // TODO: set user data in redux
        dispatch(setUser(response.data))

        // navigate to dashboard
        navigate('/dashboard')
        // window.location.pathname = '/dashboard'
      })
      .catch(function (error) {
        console.log(error)
      })
  }


  return (
    <>
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <div>
            <span>Sign In</span>
            <div className="form-group">
              <input
                name="email"
                type="email"
                className="form-control"
                placeholder="Email Address"
                value={userInformation.email}
                onChange={handleFormInputChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder="Password"
                value={userInformation.password}
                onChange={handleFormInputChange}
                required
              />
            </div>
            <div className="text-right">
              <Link to="/reset">Forgot Password?</Link>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="form-checkbox"
              />
              <label className="custom-control-label" htmlFor="form-checkbox">
                Remember me
              </label>
            </div>
            <button className="btn btn-primary" onClick={handleLoginClick}>
              Sign In
            </button>
          </div>
          <h2>
            Don't have an account? <Link to="/signup">Sign up here</Link>
          </h2>
        </div>
      </div>
    </>
  );
}

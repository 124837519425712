import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { fetchCoinsFromAPI } from '../api/slices/Coin.api';
import moment from 'moment'

export default function HistoryOrder() {
  const orderHistory = useSelector((state: RootState) => state.user.relationships.orders)
  const reversedOrderHistory = orderHistory && orderHistory.slice().reverse()

  return (
    <>
      <div className="market-history market-order mt15">
        <Tabs defaultActiveKey="order-history">
          {/* <Tab eventKey="open-orders" title="Open Orders">
            <ul className="d-flex justify-content-between market-order-item">
              <li>Time</li>
              <li>All pairs</li>
              <li>All Types</li>
              <li>Buy/Sell</li>
              <li>Price</li>
              <li>Amount</li>
              <li>Executed</li>
              <li>Unexecuted</li>
            </ul>
            <span className="no-data">
              <i className="icon ion-md-document"></i>
              No data
            </span>
          </Tab>
          <Tab eventKey="closed-orders" title="Closed Orders">
            <ul className="d-flex justify-content-between market-order-item">
              <li>Time</li>
              <li>All pairs</li>
              <li>All Types</li>
              <li>Buy/Sell</li>
              <li>Price</li>
              <li>Amount</li>
              <li>Executed</li>
              <li>Unexecuted</li>
            </ul>
            <span className="no-data">
              <i className="icon ion-md-document"></i>
              No data
            </span>
          </Tab> */}
          <Tab eventKey="order-history" title="Order history">
            <ul className="d-flex justify-content-between market-order-item">
              <li>Time</li>
              <li>All pairs</li>
              <li>All Types</li>
              <li>Buy/Sell</li>
              <li>Price</li>
              <li>Amount</li>
              <li>Executed</li>
              <li>Unexecuted</li>
            </ul>
            {!orderHistory || orderHistory.length < 1 && (
              <span className="no-data">
                <i className="icon ion-md-document"></i>
                No data
              </span>
            )}
            {reversedOrderHistory && reversedOrderHistory.length > 0 && reversedOrderHistory.slice(0, 10).map((order, index) => (
              <ul className="d-flex justify-content-between market-order-item" key={index}>
                <li>{moment(order.created_at).format('YYYY-MM-DD')}</li>
                <li>{`${order.symbol}/USDT`}</li>
                <li>{order.type}</li>
                <li>{order.side}</li>
                <li>{order.ask_at}</li>
                <li>{order.quantity}</li>
                <li>null</li>
                <li>null</li>
              </ul>
            ))}
          </Tab>
          <Tab eventKey="balance" title="Balance">
            <ul className="d-flex justify-content-between market-order-item">
              <li>Time</li>
              <li>All pairs</li>
              <li>All Types</li>
              <li>Buy/Sell</li>
              <li>Price</li>
              <li>Amount</li>
              <li>Executed</li>
              <li>Unexecuted</li>
            </ul>
            <span className="no-data">
              <i className="icon ion-md-document"></i>
              No data
            </span>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

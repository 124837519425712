import React, { useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { setActiveCoinID } from '../store/features/stateSlice/stateSlice';
import { fetchCoinsFromAPI } from '../api/slices/Coin.api';

export default function MarketPairs() {
  const coinTable = useSelector((state: RootState) => state.coins)

  const dispatch = useDispatch<AppDispatch>()

  const setActiveCoinIDToRedux = (id: number) => {
    console.log('setActiveCoinID', id)
    dispatch(setActiveCoinID(id))
  }

  useEffect(() => {
    dispatch(fetchCoinsFromAPI())
  }, [])

  return (
    <>
      <div className="market-pairs">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">
              <i className="icon ion-md-search"></i>
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            aria-describedby="inputGroup-sizing-sm"
          />
        </div>
        <Tabs defaultActiveKey="usdt">
          <Tab eventKey="star" title="★">
            <table className="table star-active">
              <thead>
                <tr>
                  <th>Pairs</th>
                  <th>Last Price</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ETH/BTC
                  </td>
                  <td>0.00020255</td>
                  <td className="red">-2.58%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KCS/BTC
                  </td>
                  <td>0.00013192</td>
                  <td className="green">+5.6%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XRP/BTC
                  </td>
                  <td>0.00002996</td>
                  <td className="red">-1.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> VET/BTC
                  </td>
                  <td>0.00000103</td>
                  <td className="green">+1.8%</td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab eventKey="usdt" title="USDT">
            <table className="table">
              <thead>
                <tr>
                  <th>Pairs</th>
                  <th>Last Price</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                {coinTable.map((coin) => (
                    <tr key={coin.id} onClick={() => setActiveCoinIDToRedux(coin.id)}>
                      <td>
                        <i className="icon ion-md-star"></i> {`${coin.symbol}/USDT`}
                      </td>
                      <td>0.00350255</td>
                      <td className="red">-2.58%</td>
                    </tr>
                ))}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IState {
  activeCoinId: number
}

const initialState: IState = {
  activeCoinId: 1
}

export const stateSlice = createSlice({
  name: 'tempState',
  initialState,
  reducers: {
    setActiveCoinID: (state, action: PayloadAction<number>) => {
      state.activeCoinId = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setActiveCoinID } = stateSlice.actions

export default stateSlice.reducer
